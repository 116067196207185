import { AccessLevels } from "../constant/AppConstant";
import { BillingInfoModel, ProfileInfoModel, ShippingInfoModel } from "./profile-model";

export const FormDataRequest = {
    BUSINESS_LICENSE_FILE_KEY: 'businessLicenseFile',
    PHARMA_BUSINESS_LICENSE_FILE_KEY: 'pharmaBusinessLicenseFile',
    GDP_GPP_GSP_FILE_KEY: 'gdpGppGspFile',
    TREATMENT_LICENSE_FILE_KEY: 'treatmentLicenseFile',
    // Malaysia
    APC_LICENSE_FILE_KEY: 'apcLicenseFile',    // Annual Practicing Certificate (Doctor && Pharmacist)
    POISON_A_LICENSE_FILE_KEY: 'poisonALicenseFile',    // Poison A License (Pharmacist)
    DATA_KEY: 'data',
    // Support import customers from file
    FILE_KEY: 'file'
};

export class CustomerInfoModel {
    constructor(vendorId) {
        this.vendorId = vendorId;
        this.customerCode = '';
        this.salesRepId = '';
        this.prefix = '';
        this.healthcareFullName = '';
        this.firstName = '';
        this.lastName = '';
        this.representativeName = '';
        this.email = '';
        this.phoneNumber = '';
        this.userGroup = '';            // Forensic group   -> not use
        this.regionGroup = '';          // customer_group
        this.specialty = '';
        this.nameOfPractice = '';
        this.natureOfBusiness = '';     // support for POMacy (Malaysia)
        this.designation = '';          // Role for subaccount users
        this.verification = '';
        this.accessLevel = AccessLevels.BASIC_LEVEL;          // Default Basic account
        this.profileInfo = new ProfileInfoModel();
        this.billingInfo = new BillingInfoModel();
        this.shippingInfo = new ShippingInfoModel();
        this.keyFile = null;
        this.agreeReceiveMessageCommercial = false;
    }

    static validate(obj, error, isAdmin = true, ignoreRegionGroup = false) {
        let result = true;

        // Require customer code
        if (!isAdmin && (obj.customerCode == null || obj.customerCode === '')) {
            error.customerCode = 'Customer Code is required';
            result = false;
        }

        // if (obj.userGroup == null || obj.userGroup === '') {
        //     error.userGroup = 'Forensic Group is required';
        //     result = false;
        // }

        if (obj.healthcareFullName == null || obj.healthcareFullName === '') {
            error.healthcareFullName = 'Healthcare Professional Full Name is required';
            result = false;
        }

        if (obj.representativeName == null || obj.representativeName === '') {
            // error.representativeName = 'Representative Name is required';
            error.representativeName = 'Business Registration Name is required';
            result = false;
        }

        if (obj.email == null || obj.email === '') {
            error.email = 'Email is required';
            result = false;
        }

        if (obj.phoneNumber == null || obj.phoneNumber === '') {
            error.phoneNumber = 'Phone Number is required';
            result = false;
        }

        // if (!ignoreRegionGroup && (obj.regionGroup == null || obj.regionGroup === '')) {
        //     error.regionGroup = 'Customer Group is required';
        //     result = false;
        // }

        if (obj.specialty == null || obj.specialty === '') {
            error.specialty = 'Specialty is required';
            result = false;
        }

        if (obj.natureOfBusiness == null || obj.natureOfBusiness === '') {
            error.natureOfBusiness = 'Nature of Business is required';
            result = false;
        }

        if (obj.designation == null || obj.designation === '') {
            error.designation = 'Designation is required';
            result = false;
        }

        return result;
    }
};
